<template>
	<div :dir="dir">
		<el-form-item
			:label="hideLabel ? null : generalMethods.translate(label)"
			:for="id == null ? prop : id"
			:prop="prop"
			:rules="rules"
			:class="`${elFormItemClassses}`"
		>
			<el-input
				:id="id == null ? prop : id"
				v-model="localModelValue"
				:autocomplete="autocomplete"
				:dir="dir"
				@input="(value) => emitEvent('input', value)"
				:type="generalMethods.valueExist(rows) ? 'textarea' : 'text'"
				:rows="rows"
				:label="ariaLabel"
				:disabled="disabled"
				:readonly="readonly"
				:placeholder="placeholder"
				:size="size"
				:inputmode="inputmode"
				:autofocus="autofocus"
			>
				<template v-if="showPrepend" #prepend>
					<slot name="prepend" />
				</template>
			</el-input>
			<template #label>
				<slot name="label" />
			</template>
		</el-form-item>
	</div>
</template>
<script lang="ts">
import { computed, defineComponent } from "vue";

import * as generalMethods from "@/core/mixins/general_methods";
import * as customValidation from "@/core/mixins/custom_validation";

export default defineComponent({
	name: "umt-element-plus-input",
	props: {
		id: { type: String, default: null },
		label: { type: String, default: null },
		prop: { type: String, default: null },
		modelValue: {},
		required: { type: Boolean, default: false },
		email: { type: Boolean, default: false },
		autocomplete: { type: String, default: null },
		dir: { type: String, default: null },
		rows: { type: Number, default: null },
		ariaLabel: { type: String, default: null },
		disabled: { type: Boolean, default: false },
		readonly: { type: Boolean, default: false },
		extraRules: { type: Array, default: null },
		showPrepend: { type: Boolean, default: false },
		placeholder: { type: String, default: null },
		hideLabel: { type: Boolean, default: false },
		size: { type: String, default: "default" },
		elFormItemClassses: { type: String, default: "" },
		inputmode: { type: String, default: "" },
		autofocus: { type: Boolean, default: false },
	},
	setup(props, { emit }) {
		const localModelValue = computed({
			get() {
				return props.modelValue;
			},
			set(newValue) {
				emit("update:modelValue", newValue);
			},
		});

		const rules = computed(() => {
			let rules = [] as Array<any>;

			if (props.required) {
				rules.push({
					validator: (rule, value, callaback) => {
						customValidation.required(rule, value, callaback, props.label);
					},
				});
			}

			if (props.email) {
				rules.push({ type: "email", message: generalMethods.translate(props.label) + " " + generalMethods.translate("is_email_field") });
			}

			if (props.extraRules != null) {
				rules = rules.concat(props.extraRules);
			}

			return rules;
		});

		const emitEvent = (event, value) => {
			emit(event, value);
		};

		return {
			generalMethods,
			customValidation,

			localModelValue,

			rules,

			emitEvent,
		};
	},
});
</script>
